@import 'styles/_variables.scss';

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  &--hidden {
    display: none;
  }
  &--no-opacity {
    opacity: 0;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  max-width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 100vh;
  overflow: auto;
}

.modal-header {
  border: none;
  h3 {
    margin-top: 0;
    color: #42b983;
  }
  b {
    font-size: 30px;
    font-weight: 800;
    width: calc(100% - 25px);
  }
  svg {
    fill: #ccc;
    width: 20px;
    height: 20px;
    position: absolute;
    /* top: 15px;
    right: 20px; */
    top: -5px;
    right: -5px;
  }
}

.modal-body {
  input {
    border-radius: 50px;
    font-weight: 600;
    text-align: center;
  }
  label {
    font-size: 13px;
    margin-bottom: 0px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .submit-button {
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    border-radius: 50px;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close-button {
  margin-top: 2px;
  cursor: pointer;
  right: -15px;
  top: -5px;
  position: absolute;
}

.modal-header {
  position: relative;
}

.modal__have-account-link {
  color: #007bff;
  cursor: pointer;
  font-weight: 800;
}

@media only screen and (max-width: $bigPhoneWidth) {
  .modal-container {
      width: 90%;
  }
}

@media only screen and (min-width: $laptopWidth) {
  .modal-container {
    width: 500px;
  }
}