@import 'styles/_variables.scss';

.nav {
  z-index: 10;
  height: 120px;
  transition: all,.3s ease-in-out;
  
  svg {
    width: 26px;
    height: 26px;
  }

  .brand {
    img {
      width: 110px;
    }
  }
}

.MoveRight {
  transform: translate(70vw);
}

@media screen and (max-width: $laptopWidth) {
  .brand {
    margin: auto;
    padding: 0px;
  }
}