@import './_variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
    text-decoration: none;
  }
  &.active-link {
    font-weight: 600;
  }

  &.external-link {
    color: #007bff;
  }
}

.background-fade {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  top: 0;
}

.full-height {
  height: calc(100vh - 57px);
}

.cursor-pointer {
  cursor: pointer;
}

.icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  /* path {
    stroke: red;
  } */
}

.external-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: red;
}

.white-fill-svg {
  svg {
    fill: #fff;
  }
}

.contain-img {
  img {
    width:100%;
  }
}

.design-border-top {
  border-top: 2px solid #000;
}

.design-border-top--light {
  border-top: 1px solid #707070;
}

.design-border-bottom--light {
  border-bottom: 1px solid #707070;
}

@media screen and (min-width: $laptopWidth) {
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } 
}