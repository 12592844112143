@import 'styles/_variables.scss';

.CartOuter {
  position: fixed;
  transform: translateX(600px);
  width: 600px;
  transition: all,.3s ease-in-out;
  height: 100vh;
  background: #fff;
  right: 0;
  z-index: 1050;
  a {
    color: #fff;
  }
  &.CartOuterActive {
    transform: none;
  }
}

.CloseButton {
  position: absolute;
  right: 35px;
  top: -25px;
}

.Title {
  font-weight: 600;
  font-size: 22px;
}

.ProductList {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
}

@media screen and (max-width: $laptopWidth) {
  .CartOuter {
    transform: translateX(100vw);
    width: 100vw;
  }
  .ProductList {
    max-height: 55vh;
  }
}