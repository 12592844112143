@import 'styles/_variables.scss';

.FooterOuter {
  background: #000;
  a {
    color: #fff;
  }
}
.Brand {
  img {
    width: 110px;
  }
}

@media screen and (max-width: $laptopWidth) {
  .FooterOuter {
    font-size: 20px;
  }
}