.black-border-button {
  border: 2px solid #000;
  text-align: center;
  width: 180px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 0px 8px 0px;
}

.btn-black {
  background: #000;
  border-radius: 0;
}