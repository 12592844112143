.CartCounter {
  background: #ccc;
  color: #000;
  border-radius: 50%;
  font-size: 11px;
  width: 16px;
  position: absolute;
  text-align: center;
  left: -12px;
  font-weight: 600;
  top: 3px;
  &.Active {
    background: #BEE0DB;
  }
}
.CartIcon {
  height: 26px;
  width: 26px;
}