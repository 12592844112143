.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  padding-top: 120px;
  transition: all,.3s ease-in-out;
  min-height: 70vh;
}

.FooterOuter {
  transition: all,.3s ease-in-out;
}

.App-content--left-nav-active, .FooterOuterLeftNavActive {
  transform: translate(70vw);
  opacity: .1;
}

.App-link {
  color: #61dafb;
}

.LeftNavCloseArea {
  position: fixed;
  width: 30vw;
  left: 70vw;
  top: 0;
  height: 100vh;
  z-index: 1050;
}
.CartCloseArea {
  transition: background-color .3s ease-in-out;
  &.CartCloseAreaActive {
    position: fixed;
    width: calc(100vw - 600px);
    left: 0;
    top: 0;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 1050;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}