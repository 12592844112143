@import 'styles/_variables.scss';

.CartProductOuter {
  img {
    width: 100%;
  }
}

.AddToCartOuter {
  display: inline-block;
}

.Title {
  font-size: 22px;
  font-weight: 600;
  word-break: break-word;
}

.Price {
  font-size: 16px;
  font-weight: 600;
}

.CloseButton {
  position: absolute;
  top: -10px;
  right: 15px;
  svg {
    fill: #ccc;
    height: 12px;
    width: 12px;
  }
}

@media screen and (max-width: $tabletWidth) {
  .Price {
    display: block;
  }
}