.svg-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.InstagramPostCard {
  img {
    width: 100%;
  }
}