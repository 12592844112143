.InputGroup input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}

.ButtonMinus, .ButtonPlus {
  background-color: #fff;
  min-width: 38px;
  width: auto;
  transition: all 300ms ease;
  font-weight: bold;
  height: 38px;
  padding: 0;
  width: 38px;
  position: relative;
  -webkit-appearance: button;
  cursor: pointer;
  font-size: 16px;
}

.Quantity {
  position: relative;
  height: 38px;
  left: -6px;
  text-align: center;
  width: 55px;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  font-size: 16px;
  &.Quantity::-webkit-outer-spin-button, &.Quantity::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.ButtonPlus {
  left: -13px;
}