.left-nav-outer {
  position: fixed;
  transform: translateX(-70vw);
  width: 70vw;
  transition: all,.3s ease-in-out;
  height: 100vh;
  background: #000;
  font-size: 20px;
  a {
    color: #fff;
  }
}

.left-nav-outer--active {
  transform: none;
}