@import 'styles/_variables.scss';

.Outer {
  background: #DAD5E2;
  svg {
    g {
      fill: #000;
    }
  }
}

.InstagramPostList {
  width: 50%;
  z-index: 1;
}

.ShapesLeft {
  position: absolute;
  left: -5%;
  top: 5%;
  width: 220px;
  img {
    width: 100%;
  }
}

.ShapesRight {
  position: absolute;
  right: 3%;
  top: 45%;
  width: 180px;
  img {
    width: 100%;
  }
}

@media only screen and (max-width: $laptopWidth) {
  .InstagramPostList {
    width: 75%;
  }
}

@media only screen and (max-width: $tabletWidth) {
  .InstagramPostList {
    width: 100%;
  }
}